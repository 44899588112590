<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="fecha">Fecha</label>
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="filtros.fecha"
          />
          <span
            class="badge alert-default-danger"
            style="font-size: 13px;"
            v-show="!filtros.fecha"
          >
            Ingrese una fecha
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4" v-if="filtros.fecha">
        <div class="form-group">
          <button
            type="button"
            class="btn bg-gradient-navy"
            @click="buscarCochadas()"
          >
            <i class="fas fa-sync fa-spin"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row" v-if="cochadas && cochadas.length > 0">
      <!-- Ventanas Enturnamiento -->
      <div class="col-md-12">
        <div class="card-navy">
          <div class="card-header pt-2 pb-2">
            <a
              data-toggle="collapse"
              href="#cochadas_liqui"
              aria-expanded="true"
              class="d-block"
              style="border: none"
            >
              <div class="row">
                <i class="fa fa-chevron-down pull-right"></i>
                <b class="ml-2">Cochadas <small>(Liquidación)</small></b>
              </div></a
            >
          </div>
          <div id="cochadas_liqui" class="collapse show">
            <div class="card card-body">
              <div
                class="col-md-12 mt-2"
                v-for="(cochada, i) in cochadas"
                :key="i"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- Descargar Plantiila -->
                    <button
                      type="button"
                      class="btn alert-default-success border border-success btn-sm"
                      style="border-radius: 0.8rem;"
                      @click="planilla(cochada.det_cochadas)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.liquidacionProrrateo.planilla'
                        )
                      "
                    >
                      <i class="far fa-file-excel"></i>
                      Plantilla
                    </button>
                    <!-- Subir Plantilla -->
                    <button
                      type="button"
                      class="btn alert-default-primary border border-primary btn-sm ml-1"
                      style="border-radius: 0.8rem;"
                      data-toggle="modal"
                      data-target="#modal_calc_prorrateo_grupo"
                      @click="changeIndiceViaje(cochada.det_cochadas, i, 1)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.liquidacionProrrateo.subirPlanilla'
                        )
                      "
                    >
                      <i class="fas fa-cloud-upload-alt"></i>
                      Subir Plantilla
                    </button>
                    <!-- Visualización Prorrateo -->
                    <button
                      type="button"
                      class="btn alert-default-info border border-info btn-sm ml-1"
                      style="border-radius: 0.8rem;"
                      data-toggle="modal"
                      data-target="#modal_data_prorrateo"
                      @click="changeIndiceViaje(cochada.det_cochadas, i, 2)"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.liquidacionProrrateo.subirPlanilla'
                        ) && cochada.prorrateo.data.length > 0
                      "
                    >
                      <i class="fas fa-eye"></i> Visualizar Prorrateo
                    </button>
                  </div>
                </div>
                <div class="card mt-2">
                  <div class="card-content w-100 p-4">
                    <b>Cochada # {{ i + 1 }}</b>
                    <div class="btn-group float-right">
                      <!-- Quitar Cochada -->
                      <button
                        type="button"
                        class="btn alert-default-danger border border-danger btn-sm"
                        style="border-radius: 0.8rem;"
                        @click="eliminarCochada(i, cochada)"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.liquidacionProrrateo.deleteCochada'
                          )
                        "
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                    <table
                      id="dataViaje"
                      class="table table-bordered table-borderless table-striped table-hover table-sm"
                      style="font-size: 0.85em;"
                    >
                      <thead class="thead-dark text-center">
                        <tr>
                          <th>No. Viaje</th>
                          <th>No. Guía</th>
                          <th>Tipo</th>
                          <th>Empresa</th>
                          <th>Cabezote</th>
                          <th>Remolque</th>
                          <th>Conductor</th>
                          <th>Barrilaje Calculado</th>
                          <th>Turno Activo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(det, j) of cochada.det_cochadas" :key="j">
                          <td class="text-center">
                            {{ det.viaje_id }}
                          </td>
                          <!-- Guia -->
                          <td class="text-center">
                            <span
                              class="badge badge-success"
                              v-if="
                                det.n_guia !== null && det.n_guia !== undefined
                              "
                            >
                              {{ det.n_guia }}
                            </span>
                            <span class="badge badge-danger" v-else>
                              Sin Asignar
                            </span>
                          </td>
                          <!-- Tipo Operación -->
                          <td class="text-center">
                            <div v-if="det.tipo_operacion">
                              <span class="badge bg-navy">{{
                                det.tipo_operacion == 2
                                  ? "Ventas A Terceros"
                                  : det.tipo_operacion == 3
                                  ? "Regalias"
                                  : "Compra sin transporte"
                              }}</span>
                            </div>
                            <div v-else>
                              <span class="badge bg-navy">
                                Ventas
                              </span>
                            </div>
                          </td>
                          <!-- Empresa Transportadora  -->
                          <td class="text-center">
                            {{ det.n_empresa }}
                          </td>
                          <!-- Vehículo  -->
                          <td class="text-center">
                            {{ det.vehiculo }}
                          </td>
                          <!-- Remolque  -->
                          <td class="text-center">
                            {{ det.remolque }}
                          </td>
                          <!-- Conductor  -->
                          <td class="text-center">
                            {{ det.nombres }}
                          </td>
                          <!-- Volumen Calculado  -->
                          <td class="text-center">
                            <span class="badge bg-navy">
                              {{ det.volumen_calculado }}
                            </span>
                          </td>
                          <!-- Posición -->
                          <td class="text-center">
                            {{ j + 1 }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-default-warning border border-warning">
          <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
          No se encontraron cochadas para esta fecha!
        </div>
      </div>
    </div>
    <!-- Modal Subir Plantilla -->
    <div class="modal fade" id="modal_calc_prorrateo_grupo">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Datos Cálculo Tk
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalPlantilla"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- Nombre -->
              <div class=" form-group col-md-3">
                <label for="descripcion">Nombre</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Nombre"
                    v-model="form.nombre"
                    :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-text-height"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Temperatura -->
              <div class=" form-group col-md-3">
                <label for="temp">Temperatura</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Temperatura"
                    v-model="form.temperatura"
                    :class="
                      $v.form.temperatura.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-thermometer-quarter"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Api -->
              <div class=" form-group col-md-3">
                <label for="api">Api</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Api"
                    v-model="form.api"
                    :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-soap"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Bsw -->
              <div class=" form-group col-md-3">
                <label for="bsw">BSW</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="BSW"
                    v-model="form.bsw"
                    :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-drum-steelpan"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Ctl -->
              <div class=" form-group col-md-3">
                <label for="ctl">Ctl</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="CTL"
                    v-model="form.ctl"
                    :class="$v.form.ctl.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-fill-drip"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Gov -->
              <div class=" form-group col-md-3">
                <label for="gov">GOV</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="GOV"
                    v-model="form.gov"
                    :class="$v.form.gov.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-tint"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Gsv -->
              <div class=" form-group col-md-3">
                <label for="gsv">GSV</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="GSV"
                    v-model="form.gsv"
                    :class="$v.form.gsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Nsv -->
              <div class=" form-group col-md-3">
                <label for="nsv">Nsv</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="NSV"
                    v-model="form.nsv"
                    :class="$v.form.nsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-radiation"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Importar Plantilla -->
            <div class="row justify-content-center">
              <div class="form-group col-md-8">
                <div class="card text-center card-footer text-muted shadow">
                  <h6><b>Importar Plantilla</b></h6>
                  <div
                    class="col-md-12 mt-2"
                    :class="
                      plantilla_cargue == null
                        ? 'alert-default-warning border border-warning'
                        : 'alert-default-success border border-success'
                    "
                  >
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      @change="elegirDirectorio()"
                      accept=".xlsx, .CSV"
                      class="form-control btn btn-secundary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('hidrocarburos.liquidacionProrrateo.subirPlanilla') &&
                !$v.form.$invalid &&
                plantilla_cargue
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="importExcel()"
            >
              Subir Plantilla
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Diferencias y Data Prorrateo -->
    <div
      class="modal fade"
      id="modal_data_prorrateo"
      v-if="indice_cochada != null"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Datos Prorrateo
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalData"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- Nombre -->
              <div class=" form-group col-md-3">
                <label for="descripcion">Nombre</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Nombre"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.nombre"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-text-height"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Temperatura -->
              <div class=" form-group col-md-3">
                <label for="temp">Temperatura</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Temperatura"
                    v-model="
                      cochadas[indice_cochada].prorrateo.tk_data.temperatura
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-thermometer-quarter"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Api -->
              <div class=" form-group col-md-3">
                <label for="api">Api</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Api"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.api"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-soap"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Bsw -->
              <div class=" form-group col-md-3">
                <label for="bsw">BSW</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="BSW"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.bsw"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-drum-steelpan"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Ctl -->
              <div class=" form-group col-md-3">
                <label for="ctl">Ctl</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="CTL"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.ctl"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-fill-drip"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Gov -->
              <div class=" form-group col-md-3">
                <label for="gov">GOV</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="GOV"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.gov"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-tint"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Gsv -->
              <div class=" form-group col-md-3">
                <label for="gsv">GSV</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="GSV"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.gsv"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                  </div>
                </div>
              </div>
              <!-- Nsv -->
              <div class=" form-group col-md-3">
                <label for="nsv">Nsv</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="NSV"
                    v-model="cochadas[indice_cochada].prorrateo.tk_data.nsv"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="fas fa-radiation"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla Diferencia -->
            <div class="row justify-content-center mt-3">
              <table
                class="table table-bordered table-striped table-hover table-sm shadow col-md-8"
              >
                <tr class="text-center text-white bg-blueB">
                  <td>
                    <span><b> VOLUMEN</b></span>
                  </td>
                  <td>
                    <span><b> DATOS TK</b></span>
                  </td>
                  <td>
                    <span><b> TOTAL DATOS</b></span>
                  </td>
                  <td>
                    <span><b> DIFERENCIA</b></span>
                  </td>
                  <td>
                    <span><b> OBSERVACIÓN</b></span>
                  </td>
                </tr>
                <tr class="text-center">
                  <td>
                    <span><b>GOV</b></span>
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.tk_data.gov }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.totGov }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.diffGov }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      :class="
                        cochadas[indice_cochada].prorrateo.diffGov == 0
                          ? 'text-success'
                          : 'text-danger'
                      "
                      ><b>
                        {{
                          cochadas[indice_cochada].prorrateo.diffGov == 0
                            ? "Prorrateo exitoso, imprima datos"
                            : "Prorrateo erroneo, validar datos"
                        }}
                      </b></span
                    >
                  </td>
                </tr>
                <tr class="text-center">
                  <td>
                    <span><b>GSV</b></span>
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.tk_data.gsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.totGsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.diffGsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      :class="
                        cochadas[indice_cochada].prorrateo.diffGsv == 0
                          ? 'text-success'
                          : 'text-danger'
                      "
                      ><b>
                        {{
                          cochadas[indice_cochada].prorrateo.diffGsv == 0
                            ? "Prorrateo exitoso, imprima datos"
                            : "Prorrateo erroneo, validar datos"
                        }}
                      </b></span
                    >
                  </td>
                </tr>
                <tr class="text-center">
                  <td>
                    <span><b>NSV</b></span>
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.tk_data.nsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.totNsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      ><b>
                        {{ cochadas[indice_cochada].prorrateo.diffNsv }}</b
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      :class="
                        cochadas[indice_cochada].prorrateo.diffNsv == 0
                          ? 'text-success'
                          : 'text-danger'
                      "
                      ><b>
                        {{
                          cochadas[indice_cochada].prorrateo.diffNsv == 0
                            ? "Prorrateo exitoso, imprima datos"
                            : "Prorrateo erroneo, validar datos"
                        }}
                      </b></span
                    >
                  </td>
                </tr>
              </table>
            </div>
            <!-- Tabla Cargue Prorrateo -->
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="btn-group float-right">
                  <button
                    class="btn alert-default-info btn-sm"
                    type="button"
                    @click="save()"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.liquidacionProrrateo.saveLiquidacion'
                      ) &&
                        cochadas[indice_cochada].prorrateo.diffGov == 0 &&
                        cochadas[indice_cochada].prorrateo.diffGsv == 0 &&
                        cochadas[indice_cochada].prorrateo.diffNsv == 0
                    "
                  >
                    <i class="fas fa-save"></i>
                    Guardar Liquidación
                  </button>
                </div>
              </div>
              <div class="col-md-12 mt-3 table-responsive">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead class="bg-dark">
                    <tr>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 8em"
                      >
                        Vehículo
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 10em"
                      >
                        Producto Liquido
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        GOV
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 5em"
                      >
                        GSV
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 5em"
                      >
                        NSV
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 5em"
                      >
                        CTL
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        API
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        Temperatura
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 8em"
                      >
                        BSW Prt
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        Sellos
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        Presión
                      </th>
                      <th
                        rowspan="2"
                        class="text-center p-3"
                        style="min-width: 5em"
                      >
                        CSW
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        Azufre
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        SAL
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        Sedimento
                      </th>
                      <th rowspan="2" class="text-center p-3">
                        RVP
                      </th>
                      <th
                        colspan="2"
                        class="text-center p-2"
                        style="min-width: 15em"
                      >
                        Insertidumbre Expandida
                      </th>
                    </tr>
                    <tr class="text-center">
                      <th>BLS</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(carguePro, index) in cochadas[this.indice_cochada]
                        .prorrateo.data"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ carguePro.placa }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.producto_liquido }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.gov }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.gsv }}
                      </td>
                      <!-- <td
                        :class="
                          carguePro.gsv != carguePro.dataCtl.gsv
                            ? 'text-center alert-default-warning'
                            : 'text-center '
                        "
                      >
                        <div>
                          <span
                            :class="
                              carguePro.gsv != carguePro.dataCtl.gsv
                                ? 'badge alert-default-danger'
                                : 'badge alert-default-success'
                            "
                          >
                            {{ carguePro.gsv }}
                          </span>
                        </div>
                        <span class="badge alert-default-success">
                          {{ carguePro.dataCtl.gsv }}
                        </span>
                      </td> -->
                      <!-- <td
                        :class="
                          carguePro.nsv != carguePro.dataCtl.nsv
                            ? 'text-center alert-default-warning'
                            : 'text-center '
                        "
                      >
                        <div>
                          <span
                            :class="
                              carguePro.nsv != carguePro.dataCtl.nsv
                                ? 'badge alert-default-danger'
                                : 'badge alert-default-success'
                            "
                          >
                            {{ carguePro.nsv }}
                          </span>
                        </div>
                        <span class="badge alert-default-success">
                          {{ carguePro.dataCtl.nsv }}
                        </span>
                      </td> -->
                      <td class="text-center">
                        {{ carguePro.nsv }}
                      </td>
                      <td
                        :class="
                          carguePro.ctl != carguePro.dataCtl.ctl
                            ? 'text-center alert-default-warning'
                            : 'text-center '
                        "
                      >
                        <div>
                          <span
                            :class="
                              carguePro.ctl != carguePro.dataCtl.ctl
                                ? 'badge alert-default-danger'
                                : 'badge alert-default-success'
                            "
                          >
                            {{ carguePro.ctl }}
                          </span>
                        </div>
                        <span class="badge alert-default-success">
                          {{ carguePro.dataCtl.ctl }}
                        </span>
                      </td>
                      <td class="text-center">
                        {{ carguePro.api }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.temperatura }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.bsw }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.sellos }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.presion }}
                      </td>
                      <td
                        :class="
                          carguePro.csw != carguePro.dataCtl.csw
                            ? 'text-center alert-default-warning'
                            : 'text-center '
                        "
                      >
                        <div>
                          <span
                            :class="
                              carguePro.csw != carguePro.dataCtl.csw
                                ? 'badge alert-default-danger'
                                : 'badge alert-default-success'
                            "
                          >
                            {{
                              carguePro.csw != null
                                ? carguePro.csw
                                : "Sin Asignar"
                            }}
                          </span>
                        </div>
                        <span class="badge alert-default-success">
                          {{ carguePro.dataCtl.csw }}
                        </span>
                      </td>
                      <td class="text-center">
                        {{ carguePro.azufre }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.sal_ptb }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.sedimento }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.rvp }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.iincer_exp_porc }}
                      </td>
                      <td class="text-center">
                        {{ carguePro.iincer_exp_bls }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "LiquidacionGrupoCochada",
  components: {},

  data() {
    return {
      cochadas: {},
      viajes: [],
      indice_cochada: null,
      plantilla_cargue: null,
      filtros: {
        ventana_enturnamiento_cargue_id: null,
        fecha: null,
      },
      form: {
        nombre: null,
        temperatura: null,
        api: null,
        bsw: null,
        ctl: null,
        gov: null,
        gsv: null,
        nsv: null,
      },
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      temperatura: {
        required,
      },
      api: {
        required,
        minValue: minValue(0),
      },
      bsw: {
        required,
        minValue: minValue(0),
      },
      ctl: {
        required,
        minValue: minValue(0),
      },
      gov: {
        required,
        minValue: minValue(0),
      },
      gsv: {
        required,
        minValue: minValue(0),
      },
      nsv: {
        required,
        minValue: minValue(0),
      },
    },
  },

  methods: {
    buscarCochadas() {
      let me = this;
      me.$parent.cargando = true;
      this.filtros.ventana_enturnamiento_cargue_id = this.$parent.filtros.ventana_enturnamiento_id;
      this.indice_cochada = null;
      axios
        .get("/api/hidrocarburos/liquidacionGuias/showCochadas", {
          params: this.filtros,
        })
        .then((response) => {
          me.cochadas = response.data;
          for (let j = 0; j < me.cochadas.length; j++) {
            const cochada = me.cochadas[j];
            cochada.prorrateo = {
              tk_data: {
                nombre: null,
                temperatura: null,
                api: null,
                bsw: null,
                ctl: null,
                gov: null,
                gsv: null,
                nsv: null,
              },
              data: [],
              totGov: 0,
              totGsv: 0,
              totNsv: 0,
              diffGov: 0,
              diffGsv: 0,
              diffNsv: 0,
            };
          }
          me.$parent.cargando = false;
        });
    },

    planilla(viajes) {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/liquidacionGuias/PlantillaProrrateo",
        data: {
          viajes: viajes,
        },
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.$parent.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    elegirDirectorio() {
      this.plantilla_cargue = this.$refs.file.files[0];
    },

    async limpiarPlantilla() {
      const fileInput = document.getElementById("file");
      fileInput.value = null;
      this.plantilla_cargue = null;
    },

    async importExcel() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("file", this.plantilla_cargue);
      formData.append("viajes", JSON.stringify(this.viajes));
      formData.append("form", JSON.stringify(this.form));
      await axios
        .post(
          "/api/hidrocarburos/liquidacionGuias/importExcelTempProrrateo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (response) => {
          this.cargando = false;
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>El archivo no se cargo.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>El archivo se cargo exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }
          await this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              if (response.data.success) {
                this.cochadas[this.indice_cochada].prorrateo.data =
                  response.data.success.cargue;
                this.cochadas[this.indice_cochada].prorrateo.tk_data =
                  response.data.success.form;
                let totGov = 0;
                let totGsv = 0;
                let totNsv = 0;
                for (
                  let i = 0;
                  i < this.cochadas[this.indice_cochada].prorrateo.data.length;
                  i++
                ) {
                  totGov += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].gov
                  );
                  totGsv += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].gsv
                  );
                  totNsv += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].nsv
                  );

                  /* Función ctl, cpl */
                  let ctl = await this.$parent.getDataCtlCpl(
                    this.cochadas[this.indice_cochada].prorrateo.data[i]
                  );

                  this.cochadas[this.indice_cochada].prorrateo.data[
                    i
                  ].dataCtl = ctl;
                }

                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totGov = totGov.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totGsv = totGsv.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totNsv = totNsv.toFixed(2);

                let diffGov =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.gov
                  ) - parseFloat(totGov);

                let diffGsv =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.gsv
                  ) - parseFloat(totGsv);

                let diffNsv =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.nsv
                  ) - parseFloat(totNsv);

                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffGov = diffGov.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffGsv = diffGsv.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffNsv = diffNsv.toFixed(2);
              }
              await this.limpiarPlantilla();
              this.$refs.closeModalPlantilla.click();
            }
          });
        })
        .catch(async (e) => {
          this.cargando = false;
          await this.$swal({
            icon: "error",
            title:
              "Ha ocurrido un error, por favor intente la acción nuevamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    save() {
      let data = {
        data_tanque: this.cochadas[this.indice_cochada].prorrateo.tk_data,
        ventana_enturnamiento_cargue_id: this.filtros.ventana_enturnamiento_id,
        cochada_id: this.cochadas[this.indice_cochada].id,
        cargue: this.cochadas[this.indice_cochada].prorrateo.data,
        accion: 2,
      };
      this.$swal({
        title: "Esta seguro de guardar esta liquidación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/hidrocarburos/liquidacionGuias/saveProrrateo",
            data: data,
          })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "La liquidación se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.buscarCochadas();
              this.$refs.closeModalData.click();
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo... " + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    changeIndiceViaje(viajes, indice, opcion) {
      if (opcion == 1) {
        this.viajes = [];
        this.indice_cochada = indice;
        for (let i = 0; i < viajes.length; i++) {
          const result = viajes[i];
          this.viajes.push({
            viaje_id: result.id,
            placa: result.vehiculo,
            volumen_calculado: result.volumen_calculado,
          });
        }
      } else {
        this.indice_cochada = indice;
      }
    },

    eliminarCochada(indice, cochada) {
      this.$swal({
        title: "Esta seguro de eliminar la cochada: #" + (indice + 1) + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              "/api/hidrocarburos/liquidacionGuias/eliminarCochada/" +
                cochada.id
            )
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Se eliminó la cochada exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.buscarCochadas();
            });
        }
      });
    },
  },
};
</script>
